<template>
  <base-section id="instructions">
    <!-- <base-section-heading
      icon="mdi-format-list-numbered"
      title="Instructions"
    /> -->
    <base-section-heading
      title="Instructions"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInstructions',
    data: () => ({
      partyName: 'YourPartyName',
      cards: [
        {
          title: 'Enter Party Name',
          subtitle: 'Step 1',
          text: 'Choose something easy to type.<br/>Ideally not <em>TOO</em> generic.',
          callout: '1',
        },
        {
          title: 'Join Party',
          subtitle: 'Step 2',
          text: '<strong>Click Join</strong> and you\'ll be sent directly to your <em>private Discord server</em>.',
          callout: '2',
        },
        {
          title: 'Share the Link',
          subtitle: 'Step 3',
          text: 'Direct your party to <a href="https://instaparty.gg/YourPartyName">instaparty.gg/<strong><em>YourPartyName</em></strong></a><em class="d-block ma-1">or</em>Join <strong><em>YourPartyName</em></strong> at <a href="https://instaparty.gg">instaparty.gg</a>',
          callout: '3',
        },
        
      ],
    }),
    watch: {
      $route: function () {
        this.updatePage()
      },
    },
    mounted () {
      this.updatePage()
    },
    methods: {
      updatePage: function () {
        const routeName = this.$route.name

        const oldPartyName = this.partyName
        const newPartyName = this.$route.params.id || 'YourPartyName' // todo: store default
        if (routeName === 'party') {
          // party page
          this.ctaText = `Ready to join '${newPartyName}'`
          this.shareLink = window.location
        } else {
          // home or other page
          this.partyName = 'YourPartyName'
        }
        this.replaceCardText(oldPartyName, newPartyName)
        this.partyName = newPartyName
      },
      replaceCardText: function (oldText, newText) {
        // change PartyName copy instances
        this.cards.forEach(obj => {
          if (obj.text.indexOf(oldText)) {
            this.$set(obj, 'text', obj.text.replaceAll(oldText, newText))
          }
        })
      },
    },
  }
</script>
